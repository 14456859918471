import { RobotStatus } from "pages/session/peerConnection/signaling/types";

export interface Size {
	height: number;
	width: number;
}
export type IActiveNavInput = "keyboard" | "joystick" | "auto-docking";
export type RtpReceiverID = "primaryCam" | "navCam";
export enum RobotPrimaryCamera {
	WIDE_CAM = "wide_cam",
	ZOOM_CAM = "zoom_cam",
}
export interface PrimaryCameraState {
	currentPrimaryCamera: RobotPrimaryCamera;
	isChangingPrimaryCameraTo: RobotPrimaryCamera | null;
}

export type CameraConfig = {
	rotationDegrees: number;
	/** Extend with crop, translation-x, translation-y, scale, */
};

export type SessionInfo = {
	response:
		| "OK"
		| "NOT_ALLOWED_BUSY"
		| "NOT_ALLOWED_ERROR"
		| "NOT_ALLOWED_NOT_READY";
	iceServers: RTCIceServer;
	signaling: {
		token: string;
		url: string;
	};
	uuid: string;
	robot: { id: string; name: string; serialNumber: string };
	pilot: { id: string; name: string; avatar?: string };
	devices?: {
		camera?: { name: string };
		microphone?: { name: string; microphoneLevel: number };
		speaker?: { name: string; speakerLevel: number };
	};
	robotStatus?: Partial<RobotStatus>;
};
