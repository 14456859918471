import React, { useState } from "react";
import "./index.scss";

interface Props {
  text?: string;
  left?: boolean;
}

const Tooltip: React.FC<Props> = ({ children, text, left, ...rest }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="tooltip-container">
      <div
        className={text && show ? "tooltip-box visible" : "tooltip-box"}
        style={
          left ? { right: "60px", left: "unset", minWidth: "97px" } : undefined
        }
      >
        {text}
        <span
          className="tooltip-arrow"
          style={
            left
              ? {
                  right: "-10px",
                  transform: "rotate( -268deg)",
                  left: "unset",
                }
              : undefined
          }
        />
      </div>
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

export default Tooltip;
