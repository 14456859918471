import React, { ReactNode, useState } from 'react';
import { IActiveNavInput } from 'types';
import './index.scss';

import drivingDisabledIcon from "images/driving-disabled.svg";
import keyboardInputIcon from "images/keyboard-controller.svg";
import dockingSuccessfulIcon from "images/docking-successful.svg";
import keyboardInputDisabledIcon from "images/keyboard-controller-disabled.svg";
import whiteCloseChevronIcon from "images/white-close-chevron.svg";


type Props = {
	activeNavInput: IActiveNavInput | null;
};

const DrivingDisabledIndicatorConfig: { icon: string; label: ReactNode } = {
	label: <p>Driving disabled</p>,
	icon: drivingDisabledIcon,
};



const NavInputIndicatorsConfig: Record<
	IActiveNavInput,
	{ icon: string; label: React.ReactNode }
> = {
	keyboard: {
		icon: keyboardInputIcon,
		label: (
			<p>
				Keyboard enabled
			</p>
		),
	},
	'auto-docking': {
		icon: dockingSuccessfulIcon,
		label: (
			<p>
				GoBe Park enabled
			</p>
		),
	},
	joystick: {
		icon: keyboardInputDisabledIcon,
		label: (
			<p>
				Keyboard disabled
			</p>
		),
	},
};

const ActiveNavigationInput: React.FC<Props> = ({
	activeNavInput,
}) => {

	const [isMinimized, setIsMinimized] = useState(false);


	const indicatorConfig =
		activeNavInput === null
			? DrivingDisabledIndicatorConfig
			: NavInputIndicatorsConfig[activeNavInput];
	return isMinimized ? (
		<div
			className='container isMinimized'
			onClick={() => setIsMinimized(false)}
		>
			<img className="navIcon" src={indicatorConfig.icon} alt="" />
		</div>
	) : (
		<div className='container'>
			<img className="navIcon" src={indicatorConfig.icon} alt="" />
			{indicatorConfig.label}
			<img className="closeIconWrapper" onClick={() => setIsMinimized(true)} src={whiteCloseChevronIcon} alt="" />
		</div>
	);
};

export default ActiveNavigationInput;
