/* eslint-disable camelcase */
import {
	useEffect,
	useState,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
} from "react";
import PeerConnectionWithSignalling, {
	PeerConnectionEndReasonCode,
	SessionState,
} from "./peerConnection";
import { ISignalingClient } from "../signaling/types";
import { DEFAULT_ROBOT_STATUS } from "../signaling/socketIOSignaling";

export type ConnectionState = RTCPeerConnectionState;

type Args = {
	signalingClient: ISignalingClient;
	onDataChannel: (dataChannel: RTCDataChannel) => void;
	onStarted: () => void;
	onEnded: (reason: PeerConnectionEndReasonCode) => void;
};

const useCallerPeerConnection = ({
	signalingClient: _signalingClient,
	onDataChannel,
	onStarted,
	onEnded,
}: Args) => {
	// we dont expect signalingClient to ever change
	const signalingClient = useRef(_signalingClient).current;

	/** a memoized peer connection to ensure referential stability. */
	const pc = useMemo(
		() => new PeerConnectionWithSignalling(signalingClient),
		[signalingClient]
	);

	/** container for media tracks added by the remote peer */
	const remoteStream = useMemo(() => new MediaStream(), []);

	const media = usePCMediaStreams(pc);

	// bind PeerConnection object to window, for debugging purposes
	useEffect(() => {
		if (process.env.NODE_ENV !== "production") {
			(window as any).peerConnection = pc;
			(window as any).sessionID = pc.uuid;
		}
		return () => {
			(window as any).peerConnection = null;
			(window as any).sessionID = undefined;
		};
	}, [pc]);

	const [sessionState, setSessionState] = useState(pc.sessionState);
	useLayoutEffect(() => {
		const onSessionStateChange = ({
			detail: { to: newState },
		}: CustomEvent<{ from: SessionState; to: SessionState }>) =>
			setSessionState(newState);

		pc.addEventListener("sessionStateChange", onSessionStateChange);
		return () => {
			pc.removeEventListener("sessionStateChange", onSessionStateChange);
		};
	}, [pc]);

	// Kill peer connection when caller component is dieing
	useLayoutEffect(() => {
		return () => {
			pc.end("CLEANUP");
			console.info("caller.peerConnection.closed");
		};
	}, [pc]);

	// cleanup remote tracks when the caller component is exiting
	useLayoutEffect(() => {
		return () => {
			remoteStream.getTracks().forEach((track) => {
				track.stop();
				remoteStream.removeTrack(track);
			});
		};
	}, [remoteStream]);

	// attach external callbacks to the peerConnection
	// As much as possible, we put external callbacks in a separate useEffect.
	// 	So that their reference changes does not affect other logic here due to re-renders
	useLayoutEffect(() => {
		pc.onDataChanel = onDataChannel;
		pc.onStarted = onStarted;
		pc.onEnded = onEnded;

		return () => {
			pc.onDataChanel = null;
			pc.onStarted = null;
			pc.onEnded = null;
		};
	}, [onDataChannel, onEnded, onStarted, pc]);

	const [primaryCameraState, setPrimaryCameraState] = useState(
		pc.primaryCameraState
	);

	const [robotStatus, setRobotStatus] = useState(DEFAULT_ROBOT_STATUS);
	const [capabilities, setCapabilities] = useState(pc.capabilities);

	useLayoutEffect(() => {
		pc.onPrimaryCameraStateChange = setPrimaryCameraState;
		pc.onRobotStatusChange = setRobotStatus;
		pc.onRobotCapabilitiesChange = setCapabilities;

		return () => {
			pc.onPrimaryCameraStateChange = null;
			pc.onRobotStatusChange = null;
			pc.onRobotCapabilitiesChange = null;
		};
	}, [pc]);

	const startPeerConnection = useCallback(
		(stream: MediaStream) => {
			pc.start(stream).catch((error) => {
				console.error("Unable to start peer connection", error);
			});
		},
		[pc]
	);

	const endPeerConnection = useCallback(
		(reason: PeerConnectionEndReasonCode = "LOCAL_HANGUP") => {
			pc.end(reason);
		},
		[pc]
	);

	const togglePrimaryCamera = useCallback(() => {
		pc.togglePrimaryCamera().catch((error) =>
			console.error("Error toggling primary camera", error)
		);
	}, [pc]);

	return {
		startPeerConnection,
		endPeerConnection,
		pausePeerConnection: pc.pause,
		unpausePeerConnection: pc.unpause,
		togglePrimaryCamera,
		primaryCameraState,
		robotStatus,
		capabilities,
		sessionState,
		...media,
	};
};

export default useCallerPeerConnection;

function usePCMediaStreams(pc: PeerConnectionWithSignalling) {
	const [primaryMedia, setPrimaryMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: pc.primaryMediaStream, transceiver: null });

	const [navMedia, setNavMedia] = useState<{
		stream: MediaStream;
		transceiver: RTCRtpTransceiver | null;
	}>({ stream: pc.navMediaStream, transceiver: null });

	useEffect(() => {
		pc.onPrimaryMediaStreamChanged = (stream, transceiver) => {
			setPrimaryMedia({ stream, transceiver });
		};
		pc.onNavMediaStreamChanged = (stream, transceiver) => {
			setNavMedia({ stream, transceiver });
		};
	}, [pc]);

	return {
		primaryMediaStream: primaryMedia.stream,
		primaryRTPTransceiver: primaryMedia.transceiver,

		navMediaStream: navMedia.stream,
		navRTPTransceiver: navMedia.transceiver,
	};
}
