import React from 'react';
import './index.scss';

import whiteWarningIcon from "images/white-warning.svg";
import whiteChevronIcon from "images/white-chevron.svg"
import refreshIcon from "images/refresh.svg";

type Props = {
	isVisible: boolean;
	closeSession: () => void;
	onClickTryAgain: () => void;
};

export default function RobotUnavailableOverlay({
	onClickTryAgain,
	closeSession,
	isVisible,
}: Props) {
	return isVisible ? (
		<div className="failedConnectionContainer">
			<img className="warningIconWrapper" alt="" src={whiteWarningIcon} />
			<div className="warningText">The Robot is unavailable, please try again later</div>
			<div className="backAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="greenCircle blackCircle" onClick={() => closeSession()}>
						<img className="iconWrapper rotateNinety" src={whiteChevronIcon} alt="" />
					</div>
					<div>Back</div>
				</div>
				<div className="circleTextContainer">
					<div className="whiteCircle blackCircle" onClick={() => onClickTryAgain()}>
						<img className="iconWrapper" src={refreshIcon} alt="" />
					</div>
					<div>Try again</div>
				</div>
			</div>
		</div>
	) : null;
}
