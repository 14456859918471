import { Battery, Network } from "components/localVideoHeader";
import { SessionInfo } from "types";

export const SIGNALING_CLIENT_KEEPALIVE_INTERVAL = 20 * 1000;

export type ICEPayload = { candidate: string | null; sdpMLineIndex: number };
export type SDPPayload = { id: string } & Omit<RTCSessionDescription, 'toJSON'>;
export type RobotStatus = { battery: Battery, network: Network };
export type Capabilities = {
	super_zoom_1: boolean;
	mouse_control_with_joystick: boolean;
	mouse_control_with_slider: boolean;
	wide_camera_rotation: number;
	zoom_camera_rotation: number;
	nav_camera_rotation: number;
}
export interface ISignalingClient {
	readonly sessionInfo: SessionInfo;

	/** Callback for when sdp is received from peer from peer
	 * @param {string} id - Identifier (from the sender's perspective) for this sdp.
	 * 	When replying back to this SDP, the same identifier must be used
	 */
	onRemoteSDP: ((data: SDPPayload) => void) | null;

	/** Callback for when ice candidate is received from peer  */
	onRemoteICECandidate: ((payload: ICEPayload) => void) | null;

	/** Callback for when peer notifies of hanging up */
	onRemoteHangUp: (() => void) | null;

	/** Callback for when the remote peer stops sending us keepalive events */
	onKeepaliveTimeout: (() => void) | null;

	/** Callback for when this instance reconnects to the underlying messaging client */
	onReconnect: (() => void) | null;

	/** Callback for when the remote peer is ending but, will retry in a bit */
	onRemoteWillRetry: (() => void) | null;

	/** Callback for when the remote peer is ready for a session retry */
	onRemoteReadyToRetry: (() => void) | null;

	/** Callback for when robot status is received from peer */
	onRemoteRobotStatus: ((payload: RobotStatus) => void) | null;

	/** Callback for when robot capabilities is received from peer */
	onRemoteRobotCapabilities: ((payload: Capabilities) => void) | null;
	/**
	 * Ensure that the signaling client is ready for signaling,
	 */
	readonly start: () => void;

	readonly sendSDPToPeer: (data: SDPPayload) => void;

	readonly sendICECandidateToPeer: (data: ICEPayload) => void;

	/**
	 * Prompt the remote peer to trigger an ICE restart sequence.
	 * The remote peer is then expected to send an new offer, in response to this prompt.
	 */
	readonly sendICERestartRequest: () => void;

	/** Prompt the remote peer to trigger a session reconnection. */
	readonly sendSessionRetryRequest: (reason?: string) => void;

	readonly sendHangup: () => void;

	/** Send to the remote peer that we are ready to retry session */
	readonly sendReadyForRetry: () => void;

	readonly sendReadyForInitialSDPOffer: () => void;

	readonly close: () => void;
}
