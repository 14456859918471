import React from "react";
import "./index.scss";
import { connect, ConnectedProps } from "react-redux";
import { SET_DRIVING_MODE_STATUS } from "actions/types";
import { PrimaryCameraState, RobotPrimaryCamera } from "types";
import { AppRootState } from "reducers";
import Tooltip from "../tooltip";
import { setParameter } from "actions/setParam";

import zoomInIcon from "images/zoom-in.svg";
import zoomOutIcon from "images/zoom-out.svg";
import drivingViewIcon from "images/driving-view.svg";

const reduxConnector = connect(
  (state: AppRootState) => ({
    dataChannel1: state.sessionState.dataChannel1,
    localVoiceVolume: state.sessionState.localVoiceVolume,
    navSpeed: state.sessionState.navSpeed,
    hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
    microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
    drivingMode: state.sessionState.drivingMode,
    navCameraHeight: state.sessionState.navCameraHeight,
  }),
  { setParameter }
);

type PropsFromParent = {
  togglePrimaryCamera: () => void;
  primaryCameraState: PrimaryCameraState;
  isSuperZoom1Enabled: boolean;
  onClickHangUp: () => void;
  localStream: MediaStream | null;
  navCameraRotation: number;
  hasPrimaryVideoStartedPlaying: boolean;
  isNavCameraVertical: boolean;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const NextCameraActionIcons: Record<RobotPrimaryCamera, string> = {
  [RobotPrimaryCamera.WIDE_CAM]: zoomInIcon,
  [RobotPrimaryCamera.ZOOM_CAM]: zoomOutIcon,
};

const RobotFeatures: React.FC<ComponentProps> = ({
  togglePrimaryCamera,
  primaryCameraState,
  hasPrimaryVideoStartedPlaying,
  dataChannel1,
  hideNavOptionsStatus,
  drivingMode,
  navCameraHeight,
  isSuperZoom1Enabled,
  setParameter,
  isNavCameraVertical,
}: ComponentProps) => {
  const isSessionOpen =
    hasPrimaryVideoStartedPlaying &&
    dataChannel1 &&
    dataChannel1.readyState === "open";
  const isSwitchingPrimaryCamera =
    primaryCameraState.isChangingPrimaryCameraTo !== null;
  const isZoomDisabled =
    (!isSuperZoom1Enabled && isSwitchingPrimaryCamera) || !isSessionOpen;
  const isZoomingIn = primaryCameraState.currentPrimaryCamera !== "wide_cam";

  const handleToggleDrivingMode = () => {
    setParameter("drivingMode", SET_DRIVING_MODE_STATUS, !drivingMode);
  };

  return (
    <div
      style={{
        left: `calc(50% - ${
          isNavCameraVertical
            ? (navCameraHeight * 0.75) / 2 + 70
            : navCameraHeight / 0.75 / 2 + 70
        }px)`,
      }}
      className={
        hideNavOptionsStatus ? "robotFeaturesContainer" : "displayNone"
      }
    >
      <Tooltip
        left
        text={
          isSessionOpen
            ? drivingMode
              ? "Meeting View"
              : "Driving View"
            : undefined
        }
      >
        <div
          className={`blackCircle ${drivingMode && "disabled"}`}
          onClick={handleToggleDrivingMode}
        >
          <img className="iconWrapper" src={drivingViewIcon} alt="" />
        </div>
      </Tooltip>
      {isSuperZoom1Enabled && (
        <Tooltip
          left
          text={
            !isZoomDisabled ? (isZoomingIn ? "Zoom Out" : "Zoom In") : undefined
          }
        >
          <div
            className={`blackCircle ${
              (isZoomDisabled || !isZoomingIn) && "disabled"
            }`}
            onClick={
              isZoomDisabled || !isSessionOpen ? undefined : togglePrimaryCamera
            }
          >
            <img
              className="iconWrapper"
              src={
                NextCameraActionIcons[primaryCameraState.currentPrimaryCamera]
              }
              alt=""
            />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default reduxConnector(RobotFeatures);
