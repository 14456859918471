import React from 'react'
import { useState, useEffect } from 'react';


type Props = {
    initialMinute?: number;
    initialSeconds?: number;
    classname?: string
}

const Timer: React.FC<Props> = (props) => {
    const { initialMinute = 0, initialSeconds = 0, classname } = props;
    const [minutes, setMinutes] = useState(initialMinute);
    const [seconds, setSeconds] = useState(initialSeconds);
    const [startDate] = useState(new Date());

    useEffect(() => {
        let myInterval = setInterval(() => {
            var now = new Date();
            const totalTimeBySeconds = Math.floor((now.getTime() - startDate.getTime()) / 1000);
            setSeconds(totalTimeBySeconds % 60);
            setMinutes(Math.floor(totalTimeBySeconds / 60));
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    return (<span className={classname}> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>)
}

export default Timer;