import React from "react";
import Timer from "../timer";
import "./index.scss";

import robotHeadIcon from "images/robot-head.svg";

class PropsFromParent {
  name: string;
}

const RobotName: React.FC<PropsFromParent> = ({ name }) => {
  return (
    <div className="robotNameContainer">
      <div className="robotHeadWrapper">
        <img className="icon" alt="" src={robotHeadIcon} />
      </div>
      <span className="name">{name} </span>
      <span>|</span>
      <Timer classname="timer" />
    </div>
  );
};

export default RobotName;
