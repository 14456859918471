/* eslint-disable camelcase */
import { ISignalingClient } from "./types";
import SocketIOSignalingClient from "./socketIOSignaling";
import { useEffect, useMemo, useRef } from "react";
import { SessionInfo } from "types";

/**
 * Signaling client factory.
 *
 * Returns a signaling client based on the robot's capabilities.
 */
export default function useSignalingClient(
  _sessionInfo: SessionInfo
): ISignalingClient {
  const sessionInfo = useRef(_sessionInfo).current;

  const signalingClient = useMemo(() => {
    return new SocketIOSignalingClient({
      ...sessionInfo,
      signaling: sessionInfo.signaling,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // close the signaling client when the component is unmounting
  useEffect(() => {
    return () => {
      if (signalingClient)
        signalingClient
          .close();
    };
  }, [signalingClient]);

  return signalingClient as ISignalingClient;
}
