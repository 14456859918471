import React from "react";
import "./index.scss";

type Props = {
	isVisible: boolean;
	onClickEndSession: () => void;
};

export default function RetryingSessionOverlay({
	onClickEndSession,
	isVisible,
}: Props) {
	return isVisible ? (
		<div className="retrying-session-container">
			<div className="loading-indicator" />

			<div className="title">{`Reconnecting ...`}</div>
			<div className="message">Your session will be automatically resumed</div>
			<div className="end-session-button" onClick={() => onClickEndSession()}>
				End Session
			</div>
		</div>
	) : null;
}
