/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "./index.scss";
import Battery from "../battery";
import { RobotStatus } from "pages/session/peerConnection/signaling/types";

import lowWiFiSignalIcon from "images/wifi-signal-low.svg";
import mediumWiFiSignalIcon from "images/wifi-signal-medium.svg";
import badWiFiSignalIcon from "images/wifi-signal-bad.svg";
import highWifiSignalIcon from "images/wifi.svg";

import removeIcon from "images/remove.svg";
import goBeHeadIcon from "images/GoBe-head.svg";

type ComponentProps = {
  robotStatus: RobotStatus;
  isLocalVideoExpanded: boolean;
  onToggleLocalVideoExpansion: () => void;
};
export type Battery = {
  level: string;
  charging: boolean;
};
export type Network = {
  quality: number;
  ssid: string | null;
};

const WifiIcons: Record<"medium" | "low" | "bad" | "high", string> = {
  low: lowWiFiSignalIcon,
  medium: mediumWiFiSignalIcon,
  bad: badWiFiSignalIcon,
  high: highWifiSignalIcon,
};

const LocalVideoHeader: React.FC<ComponentProps> = ({
  robotStatus,
  isLocalVideoExpanded,
  onToggleLocalVideoExpansion,
}) => {
  const wifiIcon = () => {
    if (robotStatus.network) {
      const quality = robotStatus.network.quality;
      switch (true) {
        case quality >= 80:
          return WifiIcons.high;
        case quality < 80 && quality >= 66:
          return WifiIcons.medium;

        case quality < 66 && quality >= 56:
          return WifiIcons.low;

        case quality < 56:
          return WifiIcons.bad;
      }
    } else {
      return WifiIcons.high;
    }
  };

  return (
    <div
      className={
        isLocalVideoExpanded
          ? " wifiBatteryContainer"
          : "miniWifiBatteryContainer wifiBatteryContainer"
      }
    >
      <img
        onClick={onToggleLocalVideoExpansion}
        className="removeIconWrapper"
        src={isLocalVideoExpanded ? removeIcon : goBeHeadIcon}
      />
      <img className="wifiIconWrapper" src={wifiIcon()} />
      <div className="batteryIconContainer">
        <Battery
          batteryPercent={robotStatus.battery.level}
          charging={robotStatus.battery.charging}
          online
        />
      </div>
    </div>
  );
};

export default React.memo(LocalVideoHeader);
