import { PrimaryCameraState, RobotPrimaryCamera } from "types";
import {
  Capabilities,
  ICEPayload,
  ISignalingClient,
  RobotStatus,
  SDPPayload,
} from "../signaling/types";
import { generateUUID } from "../utils";
import watchRTC from "@testrtc/watchrtc-sdk";
import RTCVideoStallDetector from "./rtcVideoStallDetector";
watchRTC.init();

export type PeerConnectionEndReasonCode =
  | "PEER_HANGUP"
  | "LOCAL_HANGUP"
  | "CLEANUP"
  | "FAILED_STATE_TIMED_OUT"
  | "PAUSED_STATE_TIMED_OUT"
  | "RETRY_TIMEOUT"
  | "RETRY_FAILED"
  | "ERROR";

export type RemoteTrackKey = "wide_cam" | "zoom_cam" | "nav_cam" | "audio";

export const NAV_DATACHANNEL_LABEL = "nav-datachannel";
export const NON_NAV_DATACHANNEL_LABEL = "non-nav-datachannel";
export const NON_NAV_DATACHANNEL_LABEL__LEGACY = "other-datachannel";

type IPeerConnectionEvent = "sessionStateChange";

const PAUSED_CONNECTION_TIMEOUT__MS = 15 * (60 * 1000);
const FAILED_CONNECTION_TIMEOUT__MS = 1 * 10 * 1000;
/** If the remote peer does not get back to us (within this time), that is ready to retry, we stop waiting */
const RETRYING_SESSION_TIMEOUT__MS = 20 * 1000;

/** Representation of the state of the session. */
export type SessionState =
  | "NotInitialized"
  | "InProgress"
  | "Paused"
  | "Retrying"
  | "Ended";

type PeerConnectionDetailMap = {
  sessionStateChange: { from: SessionState; to: SessionState };
};

// const STATS_SAMPLING_PERIOD_MS = 200;
export default class PeerConnectionWithSignalling {
  private sessionStateMachine = new SessionStateMachine();
  /**
   * The state of the 'session'.
   * NB: For internal use only. Should not be directly exposed outside this class.
   * Changes to the session state, should be listened to, via event handlers/callbacks
   */
  public get sessionState() {
    return this.sessionStateMachine.state;
  }

  private onSessionStateChange = (args: {
    from: SessionState;
    to: SessionState;
  }) => {
    this.dispatchEvent("sessionStateChange", args);
  };

  // todo: Refactor all callbacks to use this EventEmitter API
  private eventTarget = new EventTarget();
  public addEventListener = <T extends IPeerConnectionEvent>(
    event: T,
    listener: (event: CustomEvent<PeerConnectionDetailMap[T]>) => void
  ) => {
    this.eventTarget.addEventListener(event, listener as never);
  };

  public removeEventListener = <T extends IPeerConnectionEvent>(
    event: T,
    listener: (event: CustomEvent<PeerConnectionDetailMap[T]>) => void
  ) => {
    this.eventTarget.removeEventListener(event, listener as never);
  };

  private dispatchEvent = <T extends IPeerConnectionEvent>(
    event: T,
    detail: PeerConnectionDetailMap[T]
  ) => {
    this.eventTarget.dispatchEvent(
      new CustomEvent<PeerConnectionDetailMap[T]>(event, { detail })
    );
  };

  // private rtpStreamStatsSender: RTCRtpStreamStatsSender<LocalTrackKey | RemoteTrackKey>;

  /** A promise that resolves when we are done resetting state,
   * in order to prepare for a session retry.
   * Resolves TRUE, if the we were able to reset state for retry, else FALSE.
   * NB: This promise never rejects */
  private resetForRetryPromise = Promise.resolve(false);

  private pc: RTCPeerConnection | undefined;

  private nonNavDatachannel: RTCDataChannel | undefined;

  /** A mapping of RemoteTrackKey to corresponding MediaStreamTrack & transceiver */
  private remoteMediaKeyMap: Partial<
    Record<
      RemoteTrackKey,
      { track: MediaStreamTrack; transceiver: RTCRtpTransceiver }
    >
  > = {};

  private get remoteMediaTracks(): MediaStreamTrack[] {
    return Object.values(this.remoteMediaKeyMap).reduce((otherTracks, val) => {
      if (val === undefined) return otherTracks;
      return [...otherTracks, val.track];
    }, [] as MediaStreamTrack[]);
  }

  private localMediaStream: MediaStream | undefined;

  /** A mapping of the mid in RTCSessionDescription, to a named media track key */
  private remoteTracksMidsMap: Record<string, RemoteTrackKey> = {
    video0: "wide_cam",
    video1: "nav_cam",
    audio2: "audio",
    video3: "zoom_cam",
  };
  private _primaryCameraState: PrimaryCameraState = {
    // todo: fixme: Get the initial value from robot in the HELLO request
    currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
    isChangingPrimaryCameraTo: null,
  };
  public get primaryCameraState() {
    return this._primaryCameraState;
  }
  private _onPrimaryCameraStateChanged = (newState: PrimaryCameraState) => {
    this._primaryCameraState = newState;
    this.onPrimaryCameraStateChange?.(this.primaryCameraState);
  };

  private _capabilities: Capabilities = {
    super_zoom_1: false,
    mouse_control_with_joystick: false,
    mouse_control_with_slider: false,
    nav_camera_rotation: 90,
    wide_camera_rotation: 180,
    zoom_camera_rotation: 0,
  };
  public get capabilities() {
    return this._capabilities;
  }

  private _primaryMediaStream: MediaStream = new MediaStream();
  public get primaryMediaStream() {
    return this._primaryMediaStream;
  }

  private _navMediaStream: MediaStream = new MediaStream();
  public get navMediaStream() {
    return this._navMediaStream;
  }

  private primaryVideoStallDetector: RTCVideoStallDetector;
  private navVideoStallDetector: RTCVideoStallDetector;

  public onStarted: (() => void) | null;
  public onEnded: ((reason: PeerConnectionEndReasonCode) => void) | null;
  public onDataChanel: ((datachannel: RTCDataChannel) => void) | null;
  public onConnectionStateChange:
    | ((connectionState: RTCPeerConnectionState) => void)
    | null;
  public onPrimaryCameraStateChange:
    | ((newState: PrimaryCameraState) => void)
    | null;
  public onRobotStatusChange: ((newState: RobotStatus) => void) | null;
  public onRobotCapabilitiesChange: ((newState: Capabilities) => void) | null;
  public onPrimaryMediaStreamChanged:
    | ((stream: MediaStream, transceiver: RTCRtpTransceiver | null) => void)
    | null;
  public onNavMediaStreamChanged:
    | ((stream: MediaStream, transceiver: RTCRtpTransceiver | null) => void)
    | null;
  public onRetrying: (() => void) | null;

  constructor(private signallingClient: ISignalingClient) {
    // this.onTrack = (tr, k, trx) => console.warn('Unhandled callback onTrack', tr, k, trx);
    this.onStarted = () => console.warn("Unhandled callback onStarted");
    this.onEnded = (r) => console.warn("Unhandled callback onEnded", r);
    this.onDataChanel = (d) =>
      console.warn("Unhandled callback onDataChanel", d);

    // from the get-go, we want to know when the remote peer hangs up
    this.signallingClient.onRemoteHangUp = this.onRemoteHangUp.bind(this);
    this.signallingClient.start();

    this.sessionStateMachine.onStateChange =
      this.onSessionStateChange.bind(this);

    // this.setVolume = this.setVolume.bind(this);
    // this.setStatusMessage = this.setStatusMessage.bind(this);

    watchRTC.setConfig({
      rtcApiKey: "2610b825-e4f2-4ab0-8b27-44820805465a",
      rtcRoomId: this.signallingClient.sessionInfo.uuid,
      rtcPeerId: this.signallingClient.sessionInfo.robot.id,
      keys: {
        signalingServerUrl:
          this.signallingClient.sessionInfo.signaling?.url ?? null,
        robotId: this.signallingClient.sessionInfo.robot.id,
        // fixme: Validate the GDPR implications and uncomment/remove this
        // pilotId: this.signallingClient.sessionInfo.pilot.id,
      },
    });

    this.primaryVideoStallDetector = new RTCVideoStallDetector(null, null, {
      timeoutMs: 10 * 1000,
      initialTimeoutMs: 15 * 1000,
    });
    this.navVideoStallDetector = new RTCVideoStallDetector(null, null, {
      timeoutMs: 15 * 1000,
      initialTimeoutMs: 20 * 1000,
    });

    this.primaryVideoStallDetector.onRTCVideoStalled =
      this.onVideoStalled.bind(this)("PRIMARY");
    this.navVideoStallDetector.onRTCVideoStalled =
      this.onVideoStalled.bind(this)("NAV");

    //  bind the signaling client to global window object, so that we can call its public methods from the browser console
    (window as any).signalingClient = this.signallingClient;
  }

  private setupSignalingClientEventHandlers = () => {
    this.signallingClient.onRemoteSDP = this.onPeerSDP.bind(this);
    this.signallingClient.onRemoteICECandidate = this.onPeerICE.bind(this);
    this.signallingClient.onRemoteWillRetry =
      this.onRemotePeerWillRetry.bind(this);
    this.signallingClient.onRemoteReadyToRetry =
      this.onRemotePeerReadyToRetry.bind(this);
    this.signallingClient.onRemoteRobotStatus =
      this.onRemoteRobotStatus.bind(this);
    this.signallingClient.onRemoteRobotCapabilities =
      this.onRemoteRobotCapabilities.bind(this);
  };

  /** Initialize the peer connection, add tracks, and attach callbacks  */
  private setupPeerConnection = async (localMediaStream: MediaStream) => {
    const peerConnection = new RTCPeerConnection({
      bundlePolicy: "max-bundle",
      iceServers: [this.signallingClient.sessionInfo.iceServers],
      iceTransportPolicy: "relay",
    });
    this.pc = peerConnection;

    const videoSenders: Array<RTCRtpSender> = [];
    for (const track of localMediaStream.getTracks()) {
      const sender = peerConnection.addTrack(track, localMediaStream);
      if (track.kind === "video") {
        videoSenders.push(sender);
        // this.rtpStreamStatsSender.setStatsSource('pilotVideo', track);
      } else if (track.kind === "audio") {
        // this.rtpStreamStatsSender.setStatsSource('pilotAudio', track);
      }
    }

    // Set preferred params on RTCRtpSender for video
    for (let i = 0; i < videoSenders.length; i++) {
      const sender = videoSenders[i];
      try {
        const params = await sender.getParameters();
        const updatedParams = {
          ...params,
          encodings: params.encodings.map((encoding) => ({
            ...encoding,
            maxBitrate: 0.5 * 10 ** 6, // in bits per second
            priority: "high",
          })),
          degradationPreference: "maintain-resolution",
        };
        await sender.setParameters(updatedParams as any);
      } catch (error) {
        console.warn(
          `Error -> peerConnection.transceiver.sender.setParameters`,
          error
        );
      }
    }

    const supportsSetCodecPreferences =
      window.RTCRtpTransceiver &&
      "setCodecPreferences" in window.RTCRtpTransceiver.prototype;
    if (supportsSetCodecPreferences) {
      const { codecs } = RTCRtpSender.getCapabilities(
        "video"
      ) as RTCRtpCapabilities;
      console.log("Supported Codecs ", codecs);
      const rearrangedCodecs = [
        ...codecs.filter((codec) => codec.mimeType === "video/VP8"),
        ...codecs.filter((codec) => codec.mimeType === "video/H264"),
        ...codecs.filter(
          (codec) => !["video/H264", "video/VP8"].includes(codec.mimeType)
        ),
      ];
      const transceiver = peerConnection
        .getTransceivers()
        .find(
          (t) =>
            t.sender && t.sender.track === localMediaStream?.getVideoTracks()[0]
        );
      if (transceiver) {
        // @ts-ignore
        transceiver.setCodecPreferences(rearrangedCodecs);
        console.log("Codec preferences has been set on transceiver");
      } else {
        console.log("transceiver has not been set up on peer connection yet");
      }
    } else {
      console.warn(
        "Unfortunately, specifying preferred codec is not supported"
      );
    }

    this.localMediaStream = localMediaStream;

    peerConnection.ontrack = this._onTrack.bind(this);
    peerConnection.onicecandidate = this.onLocalICE.bind(this);
    peerConnection.onconnectionstatechange =
      this._onConnectionStateChange.bind(this);
    peerConnection.oniceconnectionstatechange =
      this._onICEConnectionStateChange.bind(this);
    peerConnection.onicegatheringstatechange =
      this._onICEGatheringStateChange.bind(this);
    peerConnection.ondatachannel = this._onDataChannel.bind(this);

    watchRTC.mapStream(localMediaStream.id, "pilotVideo");

    this.primaryVideoStallDetector.start();
    this.navVideoStallDetector.start();
  };

  public get connectionState() {
    return this.pc?.connectionState || "new";
  }

  public get uuid() {
    return this.signallingClient.sessionInfo.uuid;
  }

  public get localId() {
    return this.signallingClient.sessionInfo.pilot.id;
  }

  public get peerId() {
    return this.signallingClient.sessionInfo.robot.id;
  }

  public start = async (localStream: MediaStream) => {
    console.debug("PeerConnection.start()");

    const transitionSucceeded = this.sessionStateMachine.goToState({
      from: "NotInitialized",
      to: "InProgress",
    });
    if (!transitionSucceeded) {
      throw new Error(`abort PeerConnection.start()`);
    }

    this.setupSignalingClientEventHandlers();

    try {
      this.muteMediaTracksBasedOnPauseState();
      
      await this.setupPeerConnection(localStream);

      this.signallingClient.sendReadyForInitialSDPOffer();

      this.onStarted?.();
      // this.rtpStreamStatsSender.start(this.pc!);
    } catch (error) {
      // the remote peer did not respond with an OK :(
      console.error("peerConnection.start", error);
      // there was an error with initial signalling stage
      let reason: PeerConnectionEndReasonCode = "ERROR";
      this.end(reason);
    }
  };

  public end = (reason: PeerConnectionEndReasonCode = "LOCAL_HANGUP") => {
    console.debug("peerConnection.end", reason);
    watchRTC.addEvent({
      type: "global",
      name: "sessionEnded",
      parameters: { reason },
    });

    const transitionSucceeded = this.sessionStateMachine.goToState({
      from: ["NotInitialized", "InProgress", "Paused", "Retrying"],
      to: "Ended",
    });
    if (!transitionSucceeded) {
      if (this.sessionStateMachine.isInState("Ended")) {
        this.onEnded?.(reason);
      }
      return;
    }

    // this.rtpStreamStatsSender.stop();
    // notify the remote peer that we are hanging up
    this.signallingClient.sendHangup();

    this.cleanup();

    this.onEnded?.(reason);
  };

  /** Cleanup resources used in this class.
   * @Returns The local MediaStream if any
   */
  private cleanup = () => {
    this.resetForRetryPromise = Promise.resolve(false);

    // clear any pending timeouts
    if (this.failedConnStateTimeoutID !== undefined) {
      clearTimeout(this.failedConnStateTimeoutID);
      this.failedConnStateTimeoutID = undefined;
    }

    if (this.pausedConnTimeoutID !== undefined) {
      clearTimeout(this.pausedConnTimeoutID);
      this.pausedConnTimeoutID = undefined;
    }

    if (this.retryTimeoutID) {
      clearTimeout(this.retryTimeoutID);
      this.retryTimeoutID = undefined;
    }

    this.primaryVideoStallDetector.stop();
    this.navVideoStallDetector.stop();

    this.pc?.close();
    this.pc = undefined;

    this.remoteMediaTracks.forEach((track) => track.stop());
    this.remoteMediaKeyMap = {};

    this.primaryMediaStream
      .getTracks()
      .forEach((track) => this.primaryMediaStream.removeTrack(track));
    this.navMediaStream
      .getTracks()
      .forEach((track) => this.navMediaStream.removeTrack(track));

    this.nonNavDatachannel = undefined;

    const copyOfLocalMediaStream = this.localMediaStream;

    // NB: We don't end/stop the local tracks in this module.
    // We leave it to the creator of the tracks to end/stop when it deems it necessary.
    this.localMediaStream = undefined;

    return copyOfLocalMediaStream;
  };

  private retryTimeoutID: ReturnType<typeof setTimeout> | undefined;
  private onRemotePeerWillRetry = () => {
    const transitionSucceeded = this.sessionStateMachine.goToState({
      from: ["InProgress", "Paused"],
      to: "Retrying",
    });
    if (!transitionSucceeded) return;

    watchRTC.addEvent({ type: "global", name: "retrying", parameters: {} });

    // NB: Make sure the promise never rejects - rather returns true/false
    this.resetForRetryPromise = new Promise<boolean>((resolve) => {
      // todo: Trigger some sort of onAboutToRetry event, so that the UI can take a snapshot of the current video frame
      const localMediaStream = this.cleanup();
      if (!localMediaStream) {
        resolve(false); // no, we cannot retry session, as no local media stream is available yet
        this.end("RETRY_FAILED");
      } else {
        this.setupPeerConnection(localMediaStream)
          .then(() => {
            this._primaryMediaStream = new MediaStream();
            this._navMediaStream = new MediaStream();

            this.onPrimaryMediaStreamChanged?.(this._primaryMediaStream, null);
            this.onNavMediaStreamChanged?.(this._navMediaStream, null);

            resolve(true);
          })
          .catch((error: unknown) => {
            console.error("Failed to setupPeerConnection for retry", error);
            resolve(false);
            this.end("RETRY_FAILED");
          });
      }
    });

    // if the remote peer does not send us (in time enough),
    //  that it is ready for a retry, we will end the session
    this.retryTimeoutID = setTimeout(() => {
      this.end("RETRY_TIMEOUT");
    }, RETRYING_SESSION_TIMEOUT__MS);
  };

  private onRemotePeerReadyToRetry = () => {
    if (this.retryTimeoutID) {
      clearTimeout(this.retryTimeoutID);
      this.retryTimeoutID = undefined;
    }

    watchRTC.addEvent({
      type: "global",
      name: "remotePeerReadyForRetry",
      parameters: {},
    });

    const onSuccessfullySetupForRetry = () => {
      this.sessionStateMachine.goToState({
        from: "Retrying",
        to: "InProgress",
      });
      this.signallingClient.sendReadyForRetry();
      // todo: fixme: What if the session was paused prior to retry?
    };

    // wait for us to completely have been setup for retry
    Promise.resolve(this.resetForRetryPromise)
      .then((didSetupForRetry) => {
        this.resetForRetryPromise = Promise.resolve(false);

        if (didSetupForRetry) onSuccessfullySetupForRetry();
        else this.end("RETRY_FAILED");
      })
      .catch(console.error);
  };

  private onRemoteRobotStatus = (data: RobotStatus) => {
    this.onRobotStatusChange?.(data);
  };

  private onRemoteRobotCapabilities = async (data: Capabilities) => {
    this._capabilities = {
      ...data,
      nav_camera_rotation: data.nav_camera_rotation ?? 90,
      wide_camera_rotation: data.wide_camera_rotation ?? 180,
    };
    this.onRobotCapabilitiesChange?.(this._capabilities);
  };

  private onRemoteHangUp = () => {
    this.end("PEER_HANGUP");
  };

  /** Pause the peer connection.
   * The remote is notified of the pause, and no media is sent or played-from the remote peer
   */
  public pause = () => {
    const transitionSucceeded = this.sessionStateMachine.goToState({
      from: "InProgress",
      to: "Paused",
    });
    if (!transitionSucceeded) return;
    watchRTC.addEvent({
      type: "global",
      name: "sessionPaused",
      parameters: {},
    });

    try {
      this.nonNavDatachannel?.send("SESSION PAUSE");
    } catch (error) {
      console.error(
        "Unable to send 'SESSION PAUSE' message via datachannel",
        error
      );
    }

    this.muteMediaTracksBasedOnPauseState();

    // explicitly end the session if paused for too long
    if (this.pausedConnTimeoutID !== undefined)
      clearTimeout(this.pausedConnTimeoutID);
    this.pausedConnTimeoutID = setTimeout(
      () => this.end("PAUSED_STATE_TIMED_OUT"),
      PAUSED_CONNECTION_TIMEOUT__MS
    );
  };

  /** Resume the peer connection from a prior paused state.
   * The remote is notified of the resumption, and media sent or played-from the remote peer
   */
  public unpause = () => {
    const transitionSucceeded = this.sessionStateMachine.goToState({
      from: "Paused",
      to: "InProgress",
    });
    if (!transitionSucceeded) return;

    watchRTC.addEvent({
      type: "global",
      name: "sessionUnpaused",
      parameters: {},
    });
    try {
      this.nonNavDatachannel?.send("SESSION UNPAUSE");
    } catch (error) {
      console.error(
        "Unable to send 'SESSION UNPAUSE' message via datachannel",
        error
      );
    }
    this.muteMediaTracksBasedOnPauseState();

    if (this.pausedConnTimeoutID !== undefined) {
      clearTimeout(this.pausedConnTimeoutID);
      this.pausedConnTimeoutID = undefined;
    }
  };

  private muteMediaTracksBasedOnPauseState = (): void => {
    const isPaused = this.sessionState === "Paused";

    // mute local media
    this.localMediaStream?.getTracks().forEach((track) => {
      track.enabled = !isPaused;
    });

    // mute remote media
    this.remoteMediaTracks.forEach((track) => {
      track.enabled = !isPaused;
    });
  };

  // NB: For now, these functions will not be exposed.
  // Rather, the caller component, will directly call datachannel.send in the appropriate places
  // The ideal implementation will be to have all of such functions exposed from this class.

  // /** Set the perceived volume of our audio on the remote peer's end  */
  // public setVolume(value: Number) {
  // 	try {
  // 		this.nonNavDatachannel?.send(`VOL ${value}`);
  // 	} catch (error) {
  // 		console.error(`Unable to send 'VOL ${value}' message via datachannel`, error);
  // 	}
  // }

  // /** Send status message to the remote peer */
  // public setStatusMessage(message: String) {
  // 	try {
  // 		this.nonNavDatachannel?.send(`MSG ${message}`);
  // 	} catch (error) {
  // 		console.error(`Unable to send 'MSG ${message}' message via datachannel`, error);
  // 	}
  // }

  private _onTrack = (e: RTCTrackEvent) => {
    const remoteTrackKey = this.remoteTracksMidsMap[e.transceiver.mid!];
    if (remoteTrackKey === undefined) {
      console.error(
        "Invalid mid",
        `mid '${e.transceiver.mid}' does not correspond to any RemoteTrackKey`
      );
      return;
    }

    this.remoteMediaKeyMap[remoteTrackKey] = {
      track: e.track,
      transceiver: e.transceiver,
    };

    if (e.track.kind === "video") {
      watchRTC.mapStream(e.streams[0].id, remoteTrackKey);
    }

    if (e.track.kind === "video") {
      const isEventForCurrentPrimaryCamera =
        remoteTrackKey === this.primaryCameraState.currentPrimaryCamera;
      if (isEventForCurrentPrimaryCamera) {
        this._primaryMediaStream = new MediaStream([
          ...this.primaryMediaStream.getAudioTracks(),
          e.track,
        ]);
        this.onPrimaryMediaStreamChanged?.(
          this.primaryMediaStream,
          e.transceiver
        );
        this.primaryVideoStallDetector.setVideoSource(
          e.transceiver.receiver,
          null
        );
      } else if (remoteTrackKey === "nav_cam") {
        this._navMediaStream = new MediaStream([e.track]);
        this.onNavMediaStreamChanged?.(this.navMediaStream, e.transceiver);
        this.navVideoStallDetector.setVideoSource(e.transceiver.receiver, null);
      }
    } else {
      // audio
      this._primaryMediaStream = new MediaStream([
        ...this.primaryMediaStream.getVideoTracks(),
        e.track,
      ]);
    }

    this.muteMediaTracksBasedOnPauseState();
    // TODO: notify the statistics sender that a remote media track is available
    // this.rtpStreamStatsSender.setStatsSource(key, e.track);
  };

  private _onDataChannel = (ev: RTCDataChannelEvent) => {
    console.info("ondatachannel", ev);
    /** Labels of the other datachannel, which is not used for navigation-related stuff */
    const nonNavLabels = [
      NON_NAV_DATACHANNEL_LABEL,
      NON_NAV_DATACHANNEL_LABEL__LEGACY,
    ];
    if (nonNavLabels.includes(ev.channel.label)) {
      this.nonNavDatachannel = ev.channel;
    }
    this.onDataChanel && this.onDataChanel(ev.channel);
  };

  private _onICEGatheringStateChange = (ev: Event) => {
    console.info("ice-gathering-state ", this.pc?.iceGatheringState);
  };

  private requestSessionRetry = (reason: string) => {
    if (this.sessionStateMachine.isInState("Retrying")) return;
    this.signallingClient.sendSessionRetryRequest(reason);
  };

  private onVideoStalled = (camera: string) => () => {
    console.warn(`${camera} video stalled`);
    this.requestSessionRetry(`${camera}_VIDEO_STALLED`.toUpperCase());
  };

  /** Setup a timeout to end peer connection if `failed` for some time */
  private handleFailedConnectionState = () => {
    if (this.connectionState === "failed") {
      // a timeout has already been scheduled, abort
      if (this.failedConnStateTimeoutID !== undefined) return;

      this.failedConnStateTimeoutID = setTimeout(
        () => this.requestSessionRetry("FAILED_CONNECTION_TIMEOUT__MS"),
        FAILED_CONNECTION_TIMEOUT__MS
      );
    } else {
      if (this.failedConnStateTimeoutID !== undefined) {
        clearTimeout(this.failedConnStateTimeoutID);
        this.failedConnStateTimeoutID = undefined;
      }
    }
  };

  private getDtlsTransportsStates = () => {
    return Object.entries(this.remoteMediaKeyMap).reduce(
      (acc, [key, { transceiver }]) => {
        return {
          ...acc,
          [key]: {
            sender: transceiver.sender.transport?.state,
            receiver: transceiver.receiver.transport?.state,
          },
        };
      },
      {} as Record<
        RemoteTrackKey,
        { sender?: RTCDtlsTransportState; receiver?: RTCDtlsTransportState }
      >
    );
  };

  private _onConnectionStateChange = () => {
    console.debug(
      "peerConnection._onConnectionStateChange ",
      this.connectionState
    );
    console.debug(this.getDtlsTransportsStates());

    this.handleFailedConnectionState();
    this.onConnectionStateChange?.(this.connectionState);
  };

  private _onICEConnectionStateChange = () => {
    const iceConnectionState = this.pc?.iceConnectionState;
    if (
      iceConnectionState === "failed" ||
      iceConnectionState === "disconnected"
    ) {
      this.signallingClient.sendICERestartRequest();
    }
  };

  /** Callback to handle ICE candidates generated from this local */
  private onLocalICE = async (e: RTCPeerConnectionIceEvent) => {
    if (!e.candidate) {
      console.debug("peerConnection.onLocalICE NULL");
      return;
    }

    return this.signallingClient.sendICECandidateToPeer({
      sdpMLineIndex: e.candidate.sdpMLineIndex!,
      candidate: e.candidate?.candidate || null,
    });
  };

  /** Callback to handle sdp from peer */
  private onPeerSDP = async (data: SDPPayload) => {
    console.log("peerConnection.onPeerSDP");

    const { id: key, ...offer } = data;
    if (offer.type !== "offer") {
      console.error("peerConnection.onPeerSDP Invalid remote SDP type", offer);
      return;
    } else if (this.pc === undefined) {
      console.error(
        "this.pc is not defined. Call this.setupPeerConnection() first"
      );
      return;
    }

    // console.debug("onPeerSDP\n", offer.sdp);

    try {
      // set received offer from peer
      await this.pc.setRemoteDescription(offer);
      // set corresponding answer for the received offer
      await this.pc.setLocalDescription(await this.pc.createAnswer());
      // send answer to peer, via signalling channel
      // We use the same key as what the remote peer sent, to indicate that this answer is for that specific offer
      await this.signallingClient.sendSDPToPeer({
        id: key,
        // RTCSessionDescription doesn't seem to support spread operator.
        // So we have to manually copy the properties
        type: this.pc.localDescription!.type,
        sdp: this.pc.localDescription!.sdp,
      });
    } catch (error) {
      // catch any errors and log them only.
      // We really don't want to be throwing here in this callback
      console.error("peerConnection.onPeerSDP", error);
    }
  };

  /** Callback to handle ice from peer */
  private onPeerICE = async (data: ICEPayload) => {
    if (this.pc === undefined) {
      console.error(
        "this.pc is not defined. Call this.setupPeerConnection() first"
      );
      return;
    }
    console.debug(
      "peerConnection.onPeerICE",
      data.candidate,
      data.sdpMLineIndex
    );
    try {
      await this.pc.addIceCandidate({
        candidate: data.candidate!, // TODO: Check that the incoming candidate is never null
        sdpMLineIndex: data.sdpMLineIndex,
      });
    } catch (err) {
      console.error("peerConnection.onPeerICE error: ", err);
    }
  };

  /** Used to time out and end a session when it remains in the failed state for too long */
  private failedConnStateTimeoutID: ReturnType<typeof setTimeout> | undefined;
  /** Used to time out and end session when it is paused for too long */
  private pausedConnTimeoutID: ReturnType<typeof setTimeout> | undefined;

  public promptIceRestart = () => {
    watchRTC.addEvent({
      type: "global",
      name: "promptForIceRestart",
      parameters: {},
    });

    this.signallingClient.sendICERestartRequest();
  };

  public togglePrimaryCamera = async (): Promise<RobotPrimaryCamera> => {
    const isSwitchingPrimaryCamera =
      this.primaryCameraState.isChangingPrimaryCameraTo !== null;
    if (isSwitchingPrimaryCamera) {
      throw new Error(`Cannot switch camera. Already switching`);
    }

    const toCameraType: RobotPrimaryCamera =
      this.primaryCameraState.currentPrimaryCamera ===
      RobotPrimaryCamera.WIDE_CAM
        ? RobotPrimaryCamera.ZOOM_CAM
        : RobotPrimaryCamera.WIDE_CAM;

    watchRTC.addEvent({
      type: "global",
      name: "switchingCamera",
      parameters: {
        toCameraType,
        currentCameraType: this.primaryCameraState.currentPrimaryCamera,
      },
    });

    this._onPrimaryCameraStateChanged({
      ...this.primaryCameraState,
      isChangingPrimaryCameraTo: toCameraType,
    });
    const _switch = async () => {
      // eslint-disable-next-line camelcase
      if (!this.capabilities?.super_zoom_1)
        throw new Error(
          "GoBeSuperZoom1 is not enabled for this peer connection"
        );

      type ICommand = "REQUEST_CAMERA_SWITCH" | "SHOULD_SWITCH_CAMERA";
      type IEvent =
        | "CAN_SWITCH_CAMERA"
        | "CANNOT_SWITCH_CAMERA"
        | "DID_SWITCH_CAMERA"
        | "FAILED_TO_SWITCH_CAMERA"
        | "INVALID_CAMERA_SWITCH_MESSAGE";
      function isEventType(value: string): value is IEvent {
        const possibleValues: Record<IEvent, any> = {
          CAN_SWITCH_CAMERA: true,
          CANNOT_SWITCH_CAMERA: true,
          DID_SWITCH_CAMERA: true,
          FAILED_TO_SWITCH_CAMERA: true,
          INVALID_CAMERA_SWITCH_MESSAGE: true,
        };
        return Object.keys(possibleValues).includes(value);
      }
      /** Send a message to the remote peer over datachannel and wait for a response */
      const makeRequest = (
        dataChannel: RTCDataChannel,
        request: { type: ICommand; value: string; id: string },
        timeoutMs: number = REQUEST_TIMEOUT_MS
      ) => {
        return new Promise<{ type: IEvent; value: string }>(
          (resolve, reject) => {
            const onDataChannelMessage = (e: MessageEvent) => {
              const [type, value, forRequestId] = (e.data as string).split(" ");
              if (forRequestId !== request.id) return;

              clearTimeout(timeoutId);
              dataChannel.removeEventListener("message", onDataChannelMessage);

              if (isEventType(type)) resolve({ type: type as IEvent, value });
              else
                reject(
                  new Error(
                    `INVALID_RESPONSE ${JSON.stringify({ type, value })}`
                  )
                );
            };
            dataChannel.addEventListener("message", onDataChannelMessage);
            const timeoutId = setTimeout(() => {
              dataChannel.removeEventListener("message", onDataChannelMessage);
              reject(new Error("NO_RESPONSE"));
            }, timeoutMs);
            dataChannel.send(`${request.type} ${request.value} ${request.id}`);
          }
        );
      };

      if (!this.nonNavDatachannel) {
        throw new Error("Datachannel has not been initialized");
      }

      const requestId = generateUUID();
      const REQUEST_TIMEOUT_MS = 10 * 1000;

      await makeRequest(this.nonNavDatachannel, {
        type: "REQUEST_CAMERA_SWITCH",
        value: toCameraType,
        id: requestId,
      }).then((response) => {
        if (response.type === "CAN_SWITCH_CAMERA") {
          // const mid = Number.parseInt(response.value);
          // const expectedMediaTrackMidKey = `video${mid}`;
          // this.remoteTracksMidsMap[expectedMediaTrackMidKey] = toCameraType;
        } else if (response.type === "CANNOT_SWITCH_CAMERA")
          throw new Error(`Cannot switch camera. Reason: ${response.value}`);
        else throw Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
      });
      return makeRequest(this.nonNavDatachannel!, {
        type: "SHOULD_SWITCH_CAMERA",
        value: toCameraType,
        id: requestId,
      }).then((response) => {
        if (response.type === "DID_SWITCH_CAMERA") {
          return toCameraType;
        } else if (response.type === "FAILED_TO_SWITCH_CAMERA")
          throw new Error(`Failed to switch camera. Reason: ${response.value}`);
        else throw new Error(`INVALID_RESPONSE: ${JSON.stringify(response)}`);
      });
    };
    return _switch()
      .then((newCameraType) => {
        const mediaTrack = this.remoteMediaKeyMap[newCameraType]?.track;
        if (mediaTrack) {
          this._primaryMediaStream = new MediaStream([
            ...this.primaryMediaStream.getAudioTracks(),
            mediaTrack,
          ]);
          const transceiver =
            this.remoteMediaKeyMap[newCameraType]!.transceiver;
          this.onPrimaryMediaStreamChanged &&
            this.onPrimaryMediaStreamChanged(
              this.primaryMediaStream,
              transceiver
            );
        }

        watchRTC.addEvent({
          type: "global",
          name: "switchCameraSuccess",
          parameters: {
            toCameraType,
            currentCameraType: this.primaryCameraState.currentPrimaryCamera,
          },
        });

        this._onPrimaryCameraStateChanged({
          currentPrimaryCamera: newCameraType,
          isChangingPrimaryCameraTo: null,
        });
        return newCameraType;
      })
      .catch((error) => {
        watchRTC.addEvent({
          type: "global",
          name: "switchCameraError",
          parameters: {
            toCameraType,
            currentCameraType: this.primaryCameraState.currentPrimaryCamera,
            error,
          },
        });
        this._onPrimaryCameraStateChanged({
          ...this.primaryCameraState,
          isChangingPrimaryCameraTo: null,
        });
        throw error;
      });
  };
}

class SessionStateMachine {
  public static validStateTransitions: Record<SessionState, SessionState[]> = {
    NotInitialized: ["InProgress", "Ended"],
    InProgress: ["Paused", "Retrying", "Ended"],
    Paused: ["InProgress", "Retrying", "Ended"],
    Retrying: ["Paused", "InProgress", "Ended"],
    Ended: [], // <-- cannot go to any other state from here
  };

  public onStateChange:
    | ((args: { from: SessionState; to: SessionState }) => void)
    | undefined
    | null;

  private _state: SessionState = "NotInitialized";
  public get state(): SessionState {
    return this._state;
  }

  private _previousState: SessionState | null = null;
  public get previousState(): SessionState | null {
    return this._previousState;
  }

  public goToState(transition: {
    from: SessionState | SessionState[];
    to: SessionState;
  }): boolean {
    const isExpectedCurrentState = (
      Array.isArray(transition.from) ? transition.from : [transition.from]
    ).includes(this._state);
    const isValidTransition = SessionStateMachine.validStateTransitions[
      this._state
    ].includes(transition.to);

    if (isExpectedCurrentState && isValidTransition) {
      this._previousState = this._state;
      this._state = transition.to;
      this.onStateChange?.({ from: this._previousState, to: this._state });
      return true;
    } else {
      console.warn(
        "Invalid state transition aborted.\n" +
          `Requested transition: ${transition}.\n` +
          `Valid transitions: ${JSON.stringify({
            curr: this._state,
            next: SessionStateMachine.validStateTransitions[this._state],
          })}`
      );
      return false;
    }
  }

  public isInState(state: SessionState | SessionState[]): boolean {
    if (Array.isArray(state)) return state.some((s) => this.isInState(s));
    else return this._state === state;
  }
}
