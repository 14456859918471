import React from 'react';
import greenFlashImage from 'images/green-flash2.svg';

class PropsFromParent {
	online?: boolean;
	charging?: boolean;
	batteryPercent!: string;
}

const Battery: React.FC<PropsFromParent> = ({
	batteryPercent = '40',
	online = false,
	charging = false,
}) => {

	const batteryColor = () => {
		if (online) {
			if (charging) {
				return '#56ae4d';
			} else {
				if (parseInt(batteryPercent) > 20) {
					return '#56ae4d';
				} else if (parseInt(batteryPercent) > 10) {
					return '#e1c406';
				} else {
					return '#d92e2e';
				}
			}
		} else {
			batteryPercent = '40';
			return '#a5a5a6';
		}
	};
	const styles = {
		batteryContainer: {
			position: 'relative' as 'relative',
			width: '28px',
			height: '14px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		innerBattery: {
			width: '24px',
			height: '10px',
			borderColor: `${batteryColor()}`,
			border: `2px solid  ${batteryColor()}`,
			borderRadius: '4px',
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
		},
		batteryPercent: {
			width: `${(parseInt(batteryPercent) / 100) * 22}px`,
			height: '8px',
			borderColor: `${batteryColor()}`,
			margin: '1px',
			borderRadius: '2px',
			backgroundColor: `${batteryColor()}`,
		},
		headBattery: {
			backgroundColor: `${batteryColor()}`,
			width: '2px',
			height: '6px',
			marginLeft: '1px',
		},
		chargingWrapper: {
			position: 'absolute' as 'absolute',
			width: '15px',
			left: '5px',
			top: '-1px',
		},
		displayNone: {
			display: 'none',
		},
	};
	return (
		<div style={styles.batteryContainer}>
			<div style={styles.innerBattery}>
				<div style={styles.batteryPercent} />
			</div>
			<div style={styles.headBattery} />
			<img
				style={charging ? styles.chargingWrapper : styles.displayNone}
				alt=""
				src={greenFlashImage}
			/>
		</div>
	);
};

export default Battery;
