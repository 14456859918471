import React from 'react';
import './index.scss';

import playIcon from "images/play.svg";
import blackPauseIcon from "images/black-pause.svg";
import closeIcon from "images/close.svg";

type Props = {
	isVisible: boolean;
	isSessionPaused: boolean;
	onClickResumeSession: () => void;
	onClickPauseSession: () => void;
	onClickEndSession: () => void;
	onClickCancel: () => void;
	/** In milliseconds */
	// timeSinceSessionPaused: number; // TODO: Implement this
};

/** Overlay to ask user to confirm whether she wants to actually end the session or pause it instead */
export default function PauseOrEndSessionOverlay({
	isVisible,
	isSessionPaused,
	onClickResumeSession,
	onClickEndSession,
	onClickPauseSession,
	onClickCancel,
}: Props) {
	return !isVisible ? null : (
		<div
			className={
				isSessionPaused
					? 'sessionEndPauseOpacity sessionEndPauseContainer'
					: 'sessionEndPauseContainer'
			}
		>
			<h1>{isSessionPaused ? 'On Hold' : `Ending the session`}</h1>
			<div className="endPauseButtonsContainer">
				<div className="circleTextContainer">
					<div
						className={
							isSessionPaused ? 'greenCircle blackCircle' : 'whiteCircle blackCircle'
						}
						onClick={() =>
							isSessionPaused ? onClickResumeSession() : onClickPauseSession()
						}
					>
						<img
							className='iconWrapper'
							src={isSessionPaused ? playIcon : blackPauseIcon}
							alt=""
						/>
					</div>
					<div>{isSessionPaused ? 'Resume' : 'Pause'}</div>
				</div>
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => onClickEndSession()}>
						<img className="iconWrapper" src={closeIcon} alt="" />
					</div>
					<div>End</div>
				</div>
			</div>
			<div className="warningText">
				{isSessionPaused
					? // todo: Update this time in realtime dynamically
					'You will be auto-disconnected in 15 minutes.'
					: 'Are you sure that you want to end the session?'}
			</div>
			<div
				className={isSessionPaused ? 'displayNone' : 'cancelButtonContainer'}
				onClick={() => onClickCancel()}
			>
				CANCEL
			</div>
		</div>
	);
}
