import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { connect, ConnectedProps } from "react-redux";
import Slider from "../slider";

import { SET_LOCAL_VOICE_VOLUME, SET_NAV_SPEED } from "actions/types";
import { PrimaryCameraState } from "types";
import { AppRootState } from "reducers";
import Tooltip from "../tooltip";
import { setParameter } from "actions/setParam";

import redMicOffIcon from "images/mic-off-red.svg";
import micIcon from "images/mic.svg";
import whiteSpeedIcon from "images/white-speed.svg";
import whiteStopIcon from "images/white-stop.svg";

const reduxConnector = connect(
  (state: AppRootState) => ({
    dataChannel1: state.sessionState.dataChannel1,
    localVoiceVolume: state.sessionState.localVoiceVolume,
    navSpeed: state.sessionState.navSpeed,
    hideNavOptionsStatus: state.sessionState.hideNavOptionsStatus,
    microphoneLevel: state.hardwareState.settings.microphone.microphoneLevel,
    drivingMode: state.sessionState.drivingMode,
    navCameraHeight: state.sessionState.navCameraHeight,
  }),
  { setParameter }
);

type PropsFromParent = {
  togglePrimaryCamera: () => void;
  primaryCameraState: PrimaryCameraState;
  isSuperZoom1Enabled: boolean;
  onClickHangUp: () => void;
  localStream: MediaStream | null;
  hasPrimaryVideoStartedPlaying: boolean;
  isNavCameraVertical: boolean;
};

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type ComponentProps = PropsFromRedux & PropsFromParent;

const SessionOptions: React.FC<ComponentProps> = ({
  localStream,
  primaryCameraState,
  onClickHangUp,
  hasPrimaryVideoStartedPlaying,
  dataChannel1,
  localVoiceVolume,
  navSpeed,
  hideNavOptionsStatus,
  microphoneLevel,
  drivingMode,
  navCameraHeight,
  setParameter,
  isNavCameraVertical,
}: ComponentProps) => {
  const localVoiceRef = useRef<any>(null);
  const [localVoiceValue, changeLocalVoiceValue] = useState<any>("19");

  const onLocalVoiceChange = (value: number) => {
    setParameter("localVoiceVolume", SET_LOCAL_VOICE_VOLUME, value);
    changeLocalVoiceValue(value);
    if (dataChannel1 && dataChannel1.readyState === "open") {
      dataChannel1.send(`VOL ${value}`);
    }
  };

  useEffect(() => {
    if (microphoneLevel) {
      setParameter("localVoiceVolume", SET_LOCAL_VOICE_VOLUME, microphoneLevel);
      changeLocalVoiceValue(microphoneLevel);
    }
  }, [microphoneLevel, setParameter]);

  const onLocalVoiceIconClick = () => {
    if (
      hasPrimaryVideoStartedPlaying &&
      dataChannel1 &&
      dataChannel1.readyState === "open"
    ) {
      if (localVoiceVolume === "0") {
        if (localVoiceValue === "0") {
          if (dataChannel1 && dataChannel1.readyState === "open") {
            dataChannel1.send(`VOL 19`);
          }
          setParameter("localVoiceVolume", SET_LOCAL_VOICE_VOLUME, "19");
        } else {
          if (dataChannel1 && dataChannel1.readyState === "open") {
            dataChannel1.send(`VOL ${localVoiceValue}`);
          }
          setParameter(
            "localVoiceVolume",
            SET_LOCAL_VOICE_VOLUME,
            localVoiceValue
          );
        }
      } else {
        if (dataChannel1 && dataChannel1.readyState === "open") {
          dataChannel1.send(`VOL 0`);
        }
        setParameter("localVoiceVolume", SET_LOCAL_VOICE_VOLUME, "0");
      }
    }
  };

  const onNavSpeedChange = (value: number) => {
    setParameter("navSpeed", SET_NAV_SPEED, value);
  };

  useEffect(() => {
    if (localStream) {
      if (parseInt(localVoiceVolume) === 0) {
        localStream.getAudioTracks()[0].enabled = false;
      } else {
        localStream.getAudioTracks()[0].enabled = true;
      }
    }
  }, [localStream, localVoiceVolume]);

  const isSessionOpen =
    hasPrimaryVideoStartedPlaying &&
    dataChannel1 &&
    dataChannel1.readyState === "open";
  const isZoomingIn = primaryCameraState.currentPrimaryCamera !== "wide_cam";

  return (
    <div
      style={
        {
          right: `calc(50% - ${
            isNavCameraVertical
              ? (navCameraHeight * 0.75) / 2 + 70
              : navCameraHeight / 0.75 / 2 + 70
          }px)`,
        }
      }
      className={
        hideNavOptionsStatus ? "sessionOptionsContainer" : "displayNone"
      }
    >
      <div
        className={`roundedButton rectangle navSpeedRectangle ${
          !isSessionOpen && "disabled"
        } ${isZoomingIn && "disabled gray"}`}
      >
        <img className="iconWrapper" src={whiteSpeedIcon} alt="" />
        {isSessionOpen && !isZoomingIn && (
          <>
            <div className="localVoiceText">
              {(0.6 + (2.6 * parseInt(navSpeed)) / 100).toString().slice(0, 3)}
              <span>km/h</span>
            </div>
            <div className="rectangleLocalVoice">
              <Slider
                onChange={onNavSpeedChange}
                value={navSpeed}
                icon="speed-green.svg"
                id="navVideoSpeed"
              />
            </div>
          </>
        )}
      </div>

      <div
        className={`roundedButton rectangle ${!isSessionOpen && "disabled"}`}
      >
        <img
          className="iconWrapper"
          onClick={onLocalVoiceIconClick}
          src={parseInt(localVoiceVolume) === 0 ? redMicOffIcon : micIcon}
          alt=""
        />
        {isSessionOpen && (
          <>
            <div className="localVoiceText">{localVoiceVolume}%</div>
            <div className="rectangleLocalVoice" ref={localVoiceRef}>
              <Slider
                onChange={onLocalVoiceChange}
                value={localVoiceVolume}
                icon={
                  parseInt(localVoiceVolume) === 0
                    ? "mic-off-red.svg"
                    : "mic-off-green.svg"
                }
                id="localVideoVolume"
              />
            </div>
          </>
        )}
      </div>
      <Tooltip text={"Pause/End"}>
        <div className="roundedButton red" onClick={onClickHangUp}>
          <img
            className="iconWrapper"
            src={whiteStopIcon}
            alt=""
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default reduxConnector(SessionOptions);
